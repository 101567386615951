<template>
  <div>
    <!-- 公共头部 -->
   <Header></Header>

    <div class="section1">
      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="cate-title">{{item.title}}</div>
        <div class="box">
          <div class="pic">
            <img class="icon" :src="item.img" />
          </div>
          <div class="content">
            <div class="title">培训校区：{{item.school_title}}</div>
            <div class="desc">报名时间：{{item.addtime}}</div>
            <div class="button-group">
			<div class="btn btn1" v-if="item.classes_status==1" :class="{'normal':item.classes_status==1}">正常</div>
			<div class="btn btn1" v-else-if="item.classes_status==-1" :class="{'closed':item.classes_status==-1}">班级关闭</div>
			<div class="btn btn1" v-else-if="item.classes_status==-2" :class="{'nostarted':item.classes_status==-2}">未开班</div>
			<div class="btn btn1" v-else :class="{'end':item.classes_status==-3}">已结班</div>
			  <div class="masks" :class="{'maskes':item.classes_status!==1}" @click="stopStu"></div>
              <router-link  :to="'/simulation/choose?student_id=' + item.student_id" class="btn">开始练习</router-link>
              <!-- <div class="btn btn1" @click="openMask(index)">模拟考试</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mask" v-if="showMask"></div>
    <div class="alert-box" :class="[ showMask ? 'active' : '' ]">
      <h2 class="cate-title">考试确认</h2>
      <div class="desc">{{msg}}</div>
      <div class="button_group">
        <div class="btn" @click="closeMask">取消</div>
        <div class="btn btn1" @click="examination">开始考试</div>
      </div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		
		data() {
			return {
				title: '选择项目',
				list: [],
				showMask: false,
				msg: '',
				student_id: '',
					classData:[
					{
						title:'正常',
						state:1
					},
					{
						title:'班级关闭',
						state:-1
					},
					{
						title:'未开班',
						state:-2
					},
					{
						title:'已结班',
						state:-3
					},
				],
				classStatus:0,
				states:''

			}
		},
		computed: {
			uid() {
        		this.$store.commit('saveUid',this.$route.query.uid)
				return  this.$route.query.uid;
			}
		},
		created(){
			// this.getClassStatus();
			this.getList()
		},

		activated(){
			this.getUserInfo();
			
		},
		deactivated() {
			this.list = [];
			this.showMask = false;
			this.msg = '';
			this.student_id = '';
		},
		methods: {
			openMask(index) {
				this.msg = this.list[index].message;
				this.student_id = this.list[index].student_id;
				this.showMask = true;
			},
			closeMask() {
				this.showMask = false;
			},
			examination() {
				var student_id = this.student_id;
				this.$router.push({path: '/simulation/examination', query: { student_id }});
			},
			// 获取项目列表
			async getList() {
				var uid = this.uid;
				var data = {
					uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/analog', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('模拟考试 项目列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
        console.log(res);
				if(code == 1) {
					var list = res.data;
					for(var i = 0; i < list.length; i++) {
						list[i]['addtime'] = this.utils.js_date_time(list[i]['addtime'],2);
						if(list[i]['img'] && list[i]['img'].substring(0,4) != 'http'){
						list[i]['img']=this.$store.state.http + list[i]['img']
						}
					}
					this.list = list;
        
				
				} else {
					this.list = [];
          	  this.$messageBox.confirm( '暂无练习项目','温馨提示').then(() => {
        	   		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						   wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
     				 }).catch(()=>{})
				}
				
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
            		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return;
					}
						wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getList();
				}
			},
			
				 stopStu () {

				  this.$messageBox.confirm( '该班级暂时不能练习','温馨提示').then(() => {
        			this.$router.push('/')
     				 }).catch(()=>{})
		},
			
		},
	};
</script>

<style scoped>
.section1 {
  margin-top: 0.4rem;
  margin-bottom: 0.6rem;
}
.section1 .item {
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
  margin-top: 0.4rem;
}
.section1 .item:first-child {
  margin-top: 0;
}
.section1 .item .cate-title {
  height: 0.72rem;
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  color: #333333;
  padding: 0 0.3rem;
  border-bottom: 0.02rem solid #e0e0e0;
}
.section1 .item .box {
  padding: 0.2rem 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section1 .item .box .pic {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
}
.section1 .item .box .pic .icon {
  max-height: 100%;
}
.section1 .item .box .content {
  width: 5rem;
  /* height: 1.6rem; */
}
.section1 .item .box .content .title {
  font-size: 0.26rem;
  color: #333333;
  margin-bottom: 0.18rem;
}
.section1 .item .box .content .desc {
  font-size: 0.26rem;
  color: #333333;
}
.section1 .item .box .content .button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.3rem;
  padding: 0 10px;
}
.section1 .item .box .content .button-group .btn {
  width: 1.4rem;
  height: 0.44rem;
  font-size: 0.24rem;
  color: #333333;
  border: 0.02rem solid #333333;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.3rem;
}
.section1 .item .box .content .button-group .btn:last-child {
  margin-right: 0;
}
.section1 .item .box .content .button-group .btn1 {
  border: 0.02rem solid #ff6000;
  color: #ff6000;
}

.alert-box {
  position: fixed;
  width: 6rem;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.14rem;
  padding: 0.4rem 0.6rem;
  transition: all 0.3s;
}
.alert-box.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box .cate-title {
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  margin-bottom: 0.3rem;
}
.alert-box .desc {
  font-size: 0.26rem;
  color: #000000;
  line-height: 0.42rem;
}
.alert-box .button_group {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alert-box .button_group .btn {
  width: 2rem;
  height: 0.7rem;
  border-radius: 0.5rem;
  font-size: 0.28rem;
  color: #ffffff;
  background: #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box .button_group .btn1 {
  background: #121e5f;
}
.normal {
  border: none !important;
  color: #fff !important;
  background-color: #08852d !important;
}
.closed {
  border: none !important;
  color: #fff !important;
  background-color: #ff0000 !important;
}
.nostarted {
  border: none !important;
  color: #fff !important;
  background-color: #ff6000 !important;
}
.end {
  border: none !important;
  color: #666 !important;
  background-color: #f1f1f1 !important;
}
.button-group{
	position: relative;
}
.masks{
	width: 70px;
	height: 24px;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
}
.maskes{
	
	z-index: 99!important;
}
</style>